<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'apps-customerservice-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(customer_service_id)="data">
<!--          <b-link-->
<!--              :to="{ name: 'apps-customerservice-edit', query: { id: data.item.id } }"-->
<!--              class="font-weight-bold d-block text-nowrap"-->
<!--          >-->
            #{{ data.item.customer_service_id }}
<!--          </b-link>-->
        </template>

        <!-- Column: sales_time -->
        <template #cell(sales_time)="data">
          {{ toDate(data.item.sales_time) }}
        </template>

        <!-- Column: create_time -->
        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>

        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{getCodeLabel('customerservice_type', data.item.customerservice_type)}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: sales_order_id -->
        <template #cell(sales_order_id)="data">
          <b-button
              variant="primary"
              @click="bindSalesOrder(data.item)"
              v-if="data.item.sales_order_id == null"
          >
            <span class="text-nowrap">未绑定</span>
          </b-button>
          <b-button
            v-else-if="data.item.sales_order_id != null"
            disabled>
            <span class="text-nowrap">已绑定</span>
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--单菜单-->
<!--          <b-link>-->
<!--            <feather-icon @click="delOrderItem(data.item)"-->
<!--                icon="XSquareIcon"-->
<!--                size="16"-->
<!--                class="align-middle text-body"-->
<!--            />-->
<!--          </b-link>-->
<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-customerservice-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-customerservice-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- 添加对话框 -->
    <b-modal
        id="salesModelModal"
        ok-title="确认"
        cancel-title="取消"
        centered
        size="xl"
        title="销售订单"
        ref="salesModelModal"
        @ok="submitInfo"
    >
<!--      <div class="mb-1" @click="appCollapseChange" @dblclick="showSalesOrder">
          <app-collapse accordion>
            <app-collapse-item v-for="item in salesOrder" :title="item.order_no">
              Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie.
            </app-collapse-item>
          </app-collapse>
      </div>-->
      <CustomerSalesOrderList ref="CustomerSalesOrderList" :customerservice="customerservice"></CustomerSalesOrderList>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import customerserviceUseList from './customerserviceUseList'
import customerserviceStore from './customerserviceStore'
import { codeRowDetailsSupport } from '@/views/table/bs-table/code'
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import { codeAccordionDefault } from '@/views/components/collapse/code'
import axios from '@/libs/axios'
import CustomerSalesOrderList from '@/views/apps/customerservice/CustomerSalesOrderList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BCardCode,
    BCardText,
    AppCollapse,
    AppCollapseItem,
    CustomerSalesOrderList,
  },
  data() {
    return {
      customerservice: [],
    }
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('customerservice/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    showSalesOrder: function(data) {
      alert("订单:" +  JSON.stringify(data))
    },
  },
  mounted() {
    /*var that = this;
    axios.get('/api/salesorder/search', {params: {
        start: 1,
        limit: 10,
        order_by: 'order_id',
        order_desc: 'desc',
      }}).then(function(res) {
      that.salesOrder = res.data.data.list
    })*/
  },
  setup() {
    // Register module
    if (!store.hasModule('customerservice')) store.registerModule('customerservice', customerserviceStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('customerservice')) store.unregisterModule('customerservice')
    })

    const bindSalesOrder = function (item) {
      this.customerservice = item
      this.$refs['salesModelModal'].show()
    }

    const submitInfo = function() {
      this.$refs.CustomerSalesOrderList.saveRow(this.$refs['refListTable'])
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = customerserviceUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      bindSalesOrder,
      submitInfo,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
