<template>
  <b-card>
    <div>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-salesorder-edit'}"
                  class="hidden"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          select-mode="single"
          selectable
          @row-selected="onRowSelected"
      >
        <!-- Columns -->
        <template #cell(show_details)="row">
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
          >
            {{ row.detailsShowing ? '隐藏' : '显示' }}
          </b-form-checkbox>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card >
            <div v-for="v in row.item.ext.salesOrderItem">
            <b-row class="mb-2">
              <b-col
                  md="2"
                  class="mb-1"
              >
                <strong>仓库名称 : </strong>{{ v.warehouse_name }}
              </b-col>
              <b-col
                  md="2"
                  class="mb-1"
              >
                <strong>名称 : </strong>{{ v.name }}
              </b-col>
              <b-col
                  md="2"
                  class="mb-1"
              >
                <strong>分类名称 : </strong>{{ v.category_name }}
              </b-col>
              <b-col
                  md="2"
                  class="mb-1"
              >
                <strong>销售数量 : </strong>{{ v.qty }}
              </b-col>
              <b-col
                  md="2"
                  class="mb-1"
              >
                <strong>添加时间 : </strong>{{ toDate(v.add_time) }}
              </b-col>
              <b-col
                  md="2"
                  class="mb-1"
              >
                <strong>状态 : </strong> {{ getCodeLabel("stock_status",v.status) }}
              </b-col>
            </b-row>
            </div>
          </b-card>
          <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
          >
            隐藏
          </b-button>
        </template>

        <template #cell(order_no)="data">
          [#{{ data.item.id }}]{{ data.item.order_no }}
        </template>

        <template #cell(total_receive)="data">
          {{ data.item.total_receive || 0 }}
        </template>

        <template #cell(total_qty)="data">
          {{ data.item.total_qty || 0 }}
        </template>

        <template #cell(total_delivery_qty)="data">
          {{ data.item.total_delivery_qty || 0 }}
        </template>

        <template #cell(salesorder_type)="data">
          {{ getCodeLabel('salesorder_type', data.item.salesorder_type) }}
        </template>

        <template #cell(type_id)="data">
          {{ getCodeLabel('business_type', data.item.type_id) }}
        </template>

        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon v-if="data.value" icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(status)="data">
          <!--          <span :class="'text-'+getCode('contract_status', data.item.status).color">{{getCodeLabel('contract_status', data.item.status)}}</span>-->
          <b-badge
              pill
              :variant="`light-${getCodeColor('contract_status', data.item.status)}`"
          >
            {{ getCodeLabel("contract_status", data.item.status) }}
          </b-badge>
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-salesorder-edit', query: { id: data.item.id } }"
                             v-if="data.item.status == 0 || data.item.status == 3">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑销售单</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item)" v-if="data.item.status == 0">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status == 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">无菜单</span>
            </b-dropdown-item>

            <b-dropdown-item @click="createAutomatic(data)" v-if="data.item.status == 2" v-b-modal.modal-select>
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">创建自动出库通知单</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status == 2"
                             :to="{ name: 'apps-salesoutbound-edit', query: { orderid: data.item.order_id } }">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">创建流程出库通知单</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status === 2" v-b-modal.modal-after-sales-select>
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">售后单选择</span>
            </b-dropdown-item>


          </b-dropdown>

        </template>
      </b-table>
    </div>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
        </b-col>
        <!-- Pagination -->
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBModal
} from 'bootstrap-vue'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import customerSalesOrderUseList from './customerSalesOrderUseList'
import customerSalesOrderStore from '@/views/apps/customerservice/customerSalesOrderStore'
import store from '@/store'
import salesorderStore from '@/views/apps/salesorder/salesorderStore'
import storeStore from '@/views/apps/store/storeStore'
import salesoutboundStore from '@/views/apps/salesoutbound/salesoutboundStore'
import { onUnmounted } from '@vue/composition-api'
import Button from '@/views/components/button/Button'
import vSelect from 'vue-select'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'



export default {
  props: {
    customerservice: {
      type: Object,
    }
  },
  components: {
    Button,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BCardCode,
  },
  data() {
    return {
      rowItem: []
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('salesorder')) store.registerModule('salesorder', salesorderStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('salesoutbound')) store.registerModule('salesoutbound', salesoutboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesorder')) store.unregisterModule('salesorder')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('salesoutbound')) store.unregisterModule('salesoutbound')
    })

    const onRowSelected = function(item) {
      this.rowItem = item
    }

    const saveRow = function(table) {
      axios.post('/api/customerservice/updateCustomerAndSalesOrder', {
          salesOrder: JSON.stringify(this.rowItem),
          customerService: JSON.stringify(this.customerservice)
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            table.refresh()
          } else {
            toast.error('页面数据保存失败!', res.data.data)
          }
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = customerSalesOrderUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      toDate,

      // Event
      onRowSelected,
      saveRow,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  }
}
</script>
