import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function customerserviceUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'customer_service_id', label: 'ID', sortable: true },
    { key: 'store_company_id', label: '平台ID', sortable: true },
    { key: 'sales_name', label: '销售场次', sortable: true },
    { key: 'store_id', label: '店铺Id', sortable: true },
    { key: 'store_name', label: '店铺名称', sortable: true },
    { key: 'out_order_no', label: '外部订单编号', sortable: true },
    { key: 'customer_service_no', label: '售后单号', sortable: true },
    { key: 'sales_time', label: '销售日期', sortable: true },
    { key: 'product_name', label: '商品名称', sortable: true },
    { key: 'product_id', label: '商品ID', sortable: true },
    { key: 'out_name', label: '外部商品名称', sortable: true },
    { key: 'out_id', label: '外部商品ID', sortable: true },
    { key: 'reason', label: '售后原因', sortable: true },
    { key: 'reason_id', label: '售后原因ID', sortable: true },
    { key: 'after_sales_total', label: '退款总份数', sortable: true },
    { key: 'after_sales_amount', label: '退款总金额', sortable: true },
    { key: 'appeal_amount', label: '返回金额', sortable: true },
    { key: 'pursue_subsidy_amount', label: '追回优惠金额', sortable: true },
    { key: 'sales_order_id', label: '销售订单关联关系', sortable: true },
    { key: 'sales_order_item_id', label: '销售订单明细ID', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'is_update', label: '是否有更新', sortable: true },
    { key: 'relation_times', label: '关联次数', sortable: true },
    { key: 'create_time', label: '添加时间', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('customer_service_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('customerservice/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
